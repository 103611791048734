<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      sm="6"
    >
      <v-card>
        <v-card-title>
          Upcoming Features
        </v-card-title>

        <v-card-text>
          <v-timeline
            dense
            class="timeline-custom-dense timeline-custom-dots card-content"
          >
            <!-- Flight -->
            <v-timeline-item
              small
              color="error"
            >
              <v-card color="error">
                <v-card-title>
                  <span class="text-base white--text">Virtual Care</span>
                  <v-spacer></v-spacer>
                  <span class="d-flex align-center">
                    <v-icon
                      color="white"
                      class="me-1"
                    >
                      {{ icons.mdiVideoPlus }}
                    </v-icon>
                  </span>
                </v-card-title>
                <v-card-text class="pt-4">
                  <p class="mb-1">
                    <span>We are revolutionizing Virtual Care in Pharmacy. Virtual care goes beyond a video call or a phone call. </span>
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <!-- Interview Schedule -->
            <v-timeline-item
              small
              color="primary"
            >
              <v-card color="primary">
                <v-card-title>
                  <span class="text-base white--text">Enriched Patient Profiles</span>
                  <v-spacer></v-spacer>
                  <span class="d-flex align-center">
                    <v-icon
                      color="white"
                      class="me-1"
                    >
                      {{ icons.mdiAccountCircle }}
                    </v-icon>
                  </span>
                </v-card-title>
                <v-card-text class="pt-4">
                  <p class="mb-0">
                    We know how important it is to quickly group patients based on specific criteria. With our new Patient Profile feature, you will be able to quickly group patients and batch forms in just a few clicks.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <!-- Puma Shoes -->
            <v-timeline-item
              small
              color="info"
            >
              <v-card color="info">
                <v-card-title>
                  <span class="text-base white--text">Order Generator</span>
                  <v-spacer></v-spacer>
                  <span class="d-flex align-center">
                    <v-icon
                      color="white"
                      class="me-1"
                    >
                      {{ icons.mdiBasketPlus }}
                    </v-icon>
                  </span>
                </v-card-title>
                <v-card-text class="pt-4">
                  <p class="mb-0">
                    Every single % makes a difference. We know how important it is to optimize your generic drug ordering and to track professional allowances and order trends. Introducing Phox Smart ordering System. Inventory managememnt like never before.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>

            <!-- Design Review -->
            <v-timeline-item
              small
              color="success"
            >
              <v-card color="success">
                <v-card-title>
                  <span class="text-base white--text">Pharmacy Logs</span>
                  <v-spacer></v-spacer>
                  <span class="d-flex align-center">
                    <v-icon
                      color="white"
                      class="me-1"
                    >
                      {{ icons.mdiPost }}
                    </v-icon>
                  </span>
                </v-card-title>
                <v-card-text class="pt-4">
                  <p class="mb-0">
                    We know how tedius it can be to maintain logs for multiple aspects of your pharmacy. Now you can track all your logs in one place using one simple interface. Never stress about logs again! Create users for your staff and ensure continuous compliance and the best of quality for your patients.
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
      sm="6"
    >
      <iframe
        v-resize.quiet="{log:true}"
        width="100%"
        :src="URLmade"
        frameborder="0"
        :height="iframeHeight"
        scrolling="no"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFilePdf,
  mdiArrowRight,
  mdiMessageTextOutline,
  mdiPhone,
  mdiVideoPlus,
  mdiAccountCircle,
  mdiBasketPlus,
  mdiPost,
} from '@mdi/js'
import iFrameResize from 'iframe-resizer/js/iframeResizer'
import Vue from 'vue'
import { mapState } from 'vuex'

Vue.directive('resize', {
  bind(el, { value = {} }) {
    el.addEventListener('load', () => iFrameResize(value, el))
  },
})
export default {
  name: 'ComingSoon',
  directives: {
    'iframe-resize': {
      bind(el, binding) {
        iFrameResize(binding.value, el)
      },
    },
  },
  setup() {
    return {
      icons: {
        mdiFilePdf,
        mdiArrowRight,
        mdiMessageTextOutline,
        mdiPhone,
        mdiVideoPlus,
        mdiAccountCircle,
        mdiBasketPlus,
        mdiPost,
      },
    }
  },
  data() {
    return {
      URLmade: '',
      prePopulate: {},
      iframeHeight: '',
    }
  },
  computed: {
    ...mapState(['User']),
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.getURL()
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage(event) {
      this.eventData = event.data
      if (typeof this.eventData === 'object') {
      } else {
        const myInt = parseInt(this.eventData.replace(/^[^0-9]+/, ''), 10)
        this.iframeHeight = myInt + 40
      }
    },
    async getURL() {
      this.URLmade = `https://form.jotform.com/212736511415045?name=${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}&email=${this.$store.state.User.user.email}&nojump`
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
